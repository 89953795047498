import React, { useRef, useState } from 'react';
import '../css/App.css';
import WasmGreeter from './wasmGreeter';
import MissionStatement from './missionStatement';
import SkillSet from './skillSet';
import Articles from './articles';
import ArticleDetail from './articleDetail';

export const navContext = React.createContext();

function App() {
  const my_menu = useRef();
  const [isMissionStatement, setIsMissionStatement] = useState(false);
  const [isSkillSet, setIsSkillSet] = useState(false);
  const [isWasmDemo, setIsWasmDemo] = useState(false);
  const [isArticles, setIsArticles] = useState(false);
  const [articleDetail, setArticleDetail] = useState(null);

  const showOptions = (evt) => {
    my_menu.current.classList.toggle("hide-me");
  };

  const toggleIsMissionSatement = (evt) => {
    my_menu.current.classList.add("hide-me");
    setIsMissionStatement(!isMissionStatement);
  }

  const toggleIsSkillSet = (evt) => {
    my_menu.current.classList.add("hide-me");
    setIsSkillSet(!isSkillSet);
  }

  const toggleIsWasmDemo = (evt) => {
    my_menu.current.classList.add("hide-me");
    setIsWasmDemo(!isWasmDemo);
  }

  const toggleIsArticles = (evt) => {
    my_menu.current.classList.add("hide-me");
    setIsArticles(!isArticles);
  }

  const showArticleDetails = (article) => {
    setIsArticles(!isArticles);
    setArticleDetail(article);
  }

  const views = ['motu']; //, 'motu'];

  return (
    <navContext.Provider value={showArticleDetails}>
      <div className="App">
        <div className='explore-container'>
          <div className="explore" onClick={showOptions}>Explore</div>
          <ul ref={my_menu} className="menu-list hide-me">
            <li onClick={toggleIsMissionSatement}>Site Mission</li>
            <li onClick={toggleIsSkillSet}>Skill Set</li>
            <li onClick={toggleIsWasmDemo}>WASM Demo</li>
            <li onClick={toggleIsArticles}>Articles</li>
          </ul>
        </div>
        {
          isMissionStatement ? <MissionStatement showOptions={toggleIsMissionSatement} /> : null
        }
        {
          isSkillSet ? <SkillSet showOptions={toggleIsSkillSet} /> : null
        }
        {
          isWasmDemo ? <WasmGreeter showOptions={toggleIsWasmDemo} /> : null
        }
        {
          isArticles ? <Articles view={views} showOptions={toggleIsArticles} /> : null
        }
        {
          articleDetail ? <ArticleDetail view={articleDetail} showOptions={toggleIsArticles} /> : null
        }
      </div>
    </navContext.Provider>
  );
}

export default App;