import { Fragment, useEffect, useRef, useState } from 'react';
import { 
    draw_to_the_canvas, 
    draw_spaceship,
    greet, 
    greet_smiley 
} from '../pkg/lp_extended2';
import '../css/wasmGreeter.css';


function WasmGreeter(props) {
    const [canvasContext, setCanvasContext] = useState(null);
    const [canvasElt, setCanvasElt] = useState(null);
    const canvasRef = useRef(null);
    const [name, setName] = useState('')
    const [response, setResponse] = useState('');

    useEffect(() => {
        const canvas = canvasRef.current;
        setCanvasElt(canvas);
        const context = canvas.getContext('2d');
        setCanvasContext(context);
    }, []);

    const onClickHandler = (evt) => {
        canvasContext.clearRect(0, 0, canvasElt.width, canvasElt.height);
        const color = [1.0, 0.0, 0.0, 1.0];
        draw_spaceship();
        draw_to_the_canvas();
        greet_smiley(name);
        setResponse(greet(name));
    }

    return (
        <Fragment>
            <article className='art-was'>
                <h2>WASM Sample</h2>
                <p>React components passing variables to and invoking WASM components written in Rust.</p>
                <div className='art-was-top'>
                    <input type='text'
                        className='input-name'
                        id='name'
                        placeholder='Your name please...'
                        onChange={(evt) => setName(evt.target.value)}
                    />
                    <button className='btnGreet' onClick={(evt) => {
                        onClickHandler(evt);
                        evt.target.disabled = true;
                    }
                    }>
                        Invoke Rust WASM... 
                    </button>
                </div>
                <br />
                <p>Below are responses from WASM, a drawing on canvas and comment:</p>
                <div className='art-was-bottom'>
                    <canvas ref={canvasRef} className='wasm-canvas' id='canvas' height={400} width={900}></canvas>
                    <h3>"{response}"</h3>
                </div>
                <div className='art-was-closer' onClick={props.showOptions}>x</div>
            </article>
        </Fragment>
    );
} 

export default WasmGreeter;