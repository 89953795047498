import { Fragment, useContext, useState } from 'react';
import { navContext } from './App';
import '../css/articles.css';


const articlesIndex = [
    'life',
    'ego',
    'parents',
    'kids',
    'money'
];

function Articles(props) {
    const [isArticleSelected, setIsArticleSelected] = useState(false);
    const setArticleRequested = useContext(navContext);


    const itemSelectedHandler = (evt) => {
        const req = evt.target.getAttribute("data-value");
        setArticleRequested([req]);
    }

    return (
        <Fragment>
            <article className='art-arts'>
                <h1>Articles</h1>
                <p>
                    These articles are designed for <b>interactive reading</b>. 
                    Read between the lines like 'abstract art'...
                </p>                    
                <p>
                    Be present when reading and it will be more meaningful. Unfortunately, it will mean very little to our younger viewers! :-)
                </p>
                <ul className="art-arts-list">
                    {
                        articlesIndex.map((arti) => {
                            return (
                                <li onClick={itemSelectedHandler}
                                    key={arti}
                                    data-value={arti}>
                                        {arti}
                                </li>
                            );
                        })
                    }
                </ul>
                <p className='more'>
                    There will be more to come...
                </p>
                <div className='art-arts-closer' onClick={props.showOptions}>x</div>
            </article>
        </Fragment>
    )
}

export default Articles;