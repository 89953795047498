import { Fragment, Suspense, lazy, useEffect, useContext, useState } from 'react';
import { navContext } from './App';
import '../css/articleDetail.css';


const importView = subreddit =>
    lazy(() =>
        import(`../articles/${subreddit}`).catch(() =>
            console.log("NO VIEW!!!")
        )
    );

function ArticleDetail (props) {
    const [views, setViews] = useState([]);
    const setArticleRequested = useContext(navContext);

    const subredditsToShow = props.view;

    useEffect(() => {
        async function loadViews() {
            const compProms = 
                subredditsToShow.map(async subreddit => {
                    const View = await importView(subreddit);
                    return <View />;
                });
            Promise.all(compProms).then(setViews);
        }
        loadViews();
    }, [subredditsToShow]);

    const goBack = () => {
        setArticleRequested(null);
    }

    return (
        <Suspense fallback='Loading views...'>
            <Fragment>
                <article className='art-det'>
                    { views[0] }
                    <div className='art-det-closer' onClick={goBack}>x</div>
                </article>
            </Fragment>
        </Suspense>
    );
}

export default ArticleDetail;