import { Fragment } from 'react';
import '../css/missionStatement.css';


function MissionStatement (props) {
    return (
        <Fragment>
            <article className='art-mis'>
                <h1>Site Mission Statement</h1>
                <p>
                    This site is very clean and simple. It is built for a couple of purposes only:
                </p>
                <ol>
                    <li>
                        Enhance and support my C.V.
                    </li>
                    <li>
                        Provide a platform for <b>my friend</b> to do his <b>'experimental and thought provoking'</b> writings.
                    </li>
                </ol>
                <div className='art-mis-closer' onClick={props.showOptions}>x</div>
            </article>
        </Fragment>
    );
}

export default MissionStatement;