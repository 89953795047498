import { Fragment } from 'react';
import '../css/skillSet.css';


function SkillSet (props) {
    return (
        <Fragment>
            <article className='art-ski'>
                <h1>Technical Skills</h1>
                <p>
                    To enhance my career prospects and be ready for the next stage of the Web - 'Web3.0'.
                    I thought I would use the last three months of 2023 to solidify my knowledge and skills for Web3.
                    If you have an interesting project and think my skills & experience might be of help, do give me a ping on 
                    &nbsp;<a rel='noopener noreferrer'
                       href='https://www.linkedin.com/in/laphu/'>LinkedIn</a>.
                    <br/>
                    <br/>
                    The listed skills and experience on my CV are limited to my day job, I have created this site to show my full capabilities. 
                </p>
                <p>
                    Continuing with the theme from CVs, I will just describe what I know and can do. 
                    So, no techincal stuff to bore anyone and keep the site as simple as possible.
                    I will provide demo apps or screen prints of apps where possible.
                </p>
                <ol>
                    <li><b>HTML, CSS, JavaScript</b> and the <b>React</b> framework: This site has been built using these technologies so 
                            it is a perfect demo specimen.
                        <br/>
                        <br/>
                        Design is not great! But, functionalities and navigation should not be too bad. will
                        need to add responsiveness.
                    </li>
                    <li><b>Rust</b> programming language, <b>WebAssembly (WASM)</b>: I've added these to my skill set for well over a year now.
                    Rust is super flexible and powerful as I've used it to build WASM components as well as components that can be used and 
                    invoked by Python in a couple of my projects below. There is a little WASM demo on this site.</li>
                    <li>I understand <b>Blockchain technology</b> and is comfortable with <b>minting tokens</b> and writing <b>smart contracts</b>.
                        <div className='token'>
                            <img className='jduke-token' src={require(`../images/jduke_token.png`)} alt='JDUKE Coins' />
                            <div>A picture of my JDUKE tokens in test env.</div>
                        </div>
                    </li>
                    <li><b>Python, .NET C#</b>: My bread and butter skill set since JPMorgan days (2009). They are still current and up-to-date.</li> 
                    <li>A couple of apps I've built for family and friends geared toward retail investors/traders:
                        <p>
                            <b>ForEx Trading</b> app with real-time streaming data from broker's platform using 
                            <b> Python </b>(utilising <b>Numpy, Pandas</b> as well as <b>Core Python</b>), <b>Rust, Redis, Plotly Dash</b> (<b>Dash</b> is written 
                            on the top of <b>Flask, Plotly.js and React.js</b>). 
                        </p>
                        <div className='token'>
                            <img className='fx-app' src={require(`../images/fx_app.png`)} alt='ForEX App' />
                            <div>ForEx App with real-time data charts and trading buttons.</div>
                        </div>
                        <p>
                            <b>Equity investment portfolio management app</b> using the same technologies as the above ForEx app.
                        </p>
                        <div className='token'>
                            <img className='pm-app' src={require(`../images/port_man.png`)} alt='PM App' />
                            <div>Portfolio Management App with real-time charts, Daily and Total P&L.</div>
                        </div>
                    </li>
                </ol>
                <div className='art-ski-closer' onClick={props.showOptions}>x</div>
            </article>
        </Fragment>
    );
}

export default SkillSet;